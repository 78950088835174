export function handlePymOnIframes(documentFragment: DocumentFragment) {
  const iframesWithPym = documentFragment.querySelectorAll<HTMLIFrameElement>('iframe[data-allow-resize]');
  iframesWithPym.forEach(iframe => {
    const iframeSrc = iframe.getAttribute('src') || iframe.getAttribute('data-src') || '';

    const iframeStyle = iframe.style;
    const iframeClassName = iframe.className;
    const parentNode = iframe.parentNode;
    const iframeContainer = document.createElement('div');
    iframeContainer.setAttribute('data-pym-src', iframeSrc);
    if (iframeStyle) {
      iframeContainer.style.cssText = iframeStyle.cssText;
    }
    if (iframeClassName) {
      iframeContainer.className = iframeClassName;
    }

    if (parentNode) {
      parentNode.appendChild(iframeContainer);
      parentNode.removeChild(iframe);
    } else {
      documentFragment.appendChild(iframeContainer);
      documentFragment.removeChild(iframe);
    }
  });
}
