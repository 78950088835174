import { createUrl } from '@hubcms/utils-url';
import { HTML_EMBED_GUID_PATTERN } from '../lib/guid-pattern';
import type { HtmlEmbedOptions } from '../lib/HtmlEmbed/types';

export function injectGuidInIframes(options: HtmlEmbedOptions | undefined, documentFragment: DocumentFragment) {
  const iframesWithGuid = documentFragment.querySelectorAll<HTMLIFrameElement>(`iframe[src*='=${HTML_EMBED_GUID_PATTERN}']`);
  iframesWithGuid.forEach(iframe => {
    const url = createUrl(iframe.getAttribute('src')!); // We can enforce attribute src is not null, because the query selector defines that it should exist
    const searchParamEntries = Array.from(url.searchParams.entries());
    // If there is no GUID, remove the query param
    const mappedSearchParams = options
      ? searchParamEntries.map(([key, value]) => [key, value === HTML_EMBED_GUID_PATTERN ? options.guid : value])
      : searchParamEntries.filter(([, value]) => value !== HTML_EMBED_GUID_PATTERN);
    if (options) {
      mappedSearchParams.push(['hash', options.hash]);
    }
    url.search = new URLSearchParams(mappedSearchParams).toString();
    iframe.setAttribute('src', url.toString());
  });
}
