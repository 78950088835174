import { memo, useEffect, useRef, useState } from 'react';
import { autoInit } from 'pym.js';

import { useIntersectionObserver } from '@hubcms/utils-browser';

import { handlePymOnIframes } from '../../utils/handlePymOnIframes';
import { injectGuidInIframes } from '../../utils/injectGuidInIframes';
import type { HtmlEmbedProps } from './types';

const HtmlEmbed = memo(({ htmlBlock, className, style, options }: HtmlEmbedProps) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [hasIntersected, setHasIntersected] = useState(false);
  const ioEntry = useIntersectionObserver(divRef, {
    rootMargin: '-50px 0px 0px',
    threshold: 0.1,
  });

  useEffect(() => {
    if (ioEntry?.isIntersecting) {
      setHasIntersected(true);
    }
  }, [ioEntry?.isIntersecting]);

  useEffect(() => {
    if (!htmlBlock || !divRef.current || !hasIntersected) {
      return;
    }

    // Put data inside shadow dom if it's a whole html document
    // Using [\s\S] instead of . matches multiple lines between the tags
    if (/<html.*>[\s\S]*<\/html>$/m.test(htmlBlock)) {
      const shadow = divRef.current.attachShadow({ mode: 'open' });
      shadow.innerHTML = htmlBlock;
      autoInit(false);
      return;
    }

    const slotHtml = document.createRange().createContextualFragment(htmlBlock); // Create a 'tiny' document and parse the html string

    injectGuidInIframes(options, slotHtml);
    handlePymOnIframes(slotHtml);

    divRef.current.innerHTML = ''; // Clear the container
    divRef.current.appendChild(slotHtml); // Append the new content
    autoInit(false);
  }, [hasIntersected, htmlBlock, divRef, options]);

  if (!htmlBlock) {
    return null;
  }

  return <div className={className} style={style} ref={divRef} />;
});

export default HtmlEmbed;
